import * as React from "react"
import { Heading, Paragraph, Card, CardHeader, CardBody, CardFooter, Button } from "grommet"
import { ShareOption, Favorite } from "grommet-icons"
import Layout from "../components/layout"

const ProjectsPage = () => {
	return (
		<Layout pageTitle="Project Gallery">
			<Heading>Gallery</Heading>
			<Paragraph>Unnamed Auto Detailing - Coming soon!</Paragraph>
			<Paragraph></Paragraph>

			<Card height="small" width="small" background="light-1">
				<CardHeader pad="medium">Project #1</CardHeader>
				<CardBody pad="medium">Image/Description</CardBody>
				<CardFooter pad={{ horizontal: "small" }} background="light-2">
					<Button icon={<Favorite color="red" />} hoverIndicator />
					<Button icon={<ShareOption color="plain" />} hoverIndicator />
				</CardFooter>
			</Card>
		</Layout>
	)
}

export default ProjectsPage
